import React, { Component } from 'react';
import wordmark from '../assets/ch_wordmark.png';

export default class Banner extends Component {

    render() {
        return(
            <React.Fragment>
                <img src={wordmark} className="img-fluid" />
                <h4 className="text-center m-3">Full stack web developer based in Switzerland.</h4>
            </React.Fragment>
        );
    }

}
