import React, { Component } from 'react';
import uatv from '../assets/uatv_logo.png';
import sg from '../assets/sg_logo.png';

export default class Projects extends Component {
    projects = [
        {
            iconUrl: uatv,
            title: "Unauthorized.TV",
            url: "https://unauthorized.tv",
            description: "Subscription-based video streaming service"
        },
        {
            iconUrl: sg,
            title: "SocialGalactic",
            url: "http://sg.chris-hawke.com",
            description: "Subscription-based social media site"
        }
    ]

    render() {
        return (
            <React.Fragment>
                <h1 className="text-center m-5">My Projects</h1>
                <React.Fragment>
                    {this.getProjectGrid()}
                </React.Fragment>
            </React.Fragment>
        );
    }

    getProjectGrid() {
        if (this.projects.length === 0) return <p className="text-center"><em>Coming soon...</em></p>;
        return (
            <div id="projectGrid" className="container">
                <div className="row row-cols-1 row-cols-md-4 justify-content-center">
                    {this.projects.map(project => {
                        return (
                            <div className="col p-2">
                                <a className="link-dark text-decoration-none" href={project.url}>
                                    <div className="card border-0">
                                        <div className="row g-0">
                                            <div className="col-4">
                                                <img className="img-fluid rounded" src={project.iconUrl}/>
                                            </div>
                                            <div className="col-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">{project.title}</h5>
                                                    <p className="card-text">{project.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}