import React, { Component } from 'react';
import avatar from '../assets/ch_avatar.jpg';

export default class Contact extends Component {
    email = 'chris@infogalactic.com'
    emailUrl = 'mailto:' + this.email

    render() {
        return (
            <React.Fragment>
                <h1 className="text-center m-5">Contact</h1>
                <div className="container mb-5">
                    <div className="row row-cols-1 row-cols-md-4 justify-content-center">
                        <div className="col">
                            <a className="link-dark text-decoration-none" href={this.emailUrl}>
                                <div className="card border-0">
                                    <div className="row">
                                        <div className="col-4 p-2">
                                            <img className="img-fluid rounded-circle" src={avatar}/>
                                        </div>
                                        <div className="col-8 d-flex flex-column justify-content-center">
                                            <h4 className="card-title">Email</h4>
                                            <p className="card-text">{this.email}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
