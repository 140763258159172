import React, { Component } from 'react';
import Banner from './components/banner';
import Projects from './components/projects';
import Contact from './components/contact';

export default class App extends Component {

    render() {
        return (
            <main className="container">
                <Banner />
                <Projects />
                <Contact />
            </main>
        );
    }

};
